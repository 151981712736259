import "./App.css";
import Zero from "./components/numbers/Zero";
import One from "./components/numbers/One";
import styled from "styled-components";
import Two from "./components/numbers/Two";
import Three from "./components/numbers/Three";
import Four from "./components/numbers/Four";
import Five from "./components/numbers/Five";
import Six from "./components/numbers/Six";
import Seven from "./components/numbers/Seven";
import Eight from "./components/numbers/Eight";
import Nine from "./components/numbers/Nine";
import moment from "moment";
import { useEffect, useState } from "react";
import Timetable from "./components/timetable/Timetable";
import Confetti from "react-dom-confetti";

const Numbers = [Zero, One, Two, Three, Four, Five, Six, Seven, Eight, Nine];

const config = {
  angle: 90,
  spread: 360,
  startVelocity: 80,
  elementCount: 10,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: "10px",
  height: "10px",
  perspective: "500px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
};

function renderNumbers(diffTimeUnit, height, width) {
  return (
    <>
      {diffTimeUnit.split("").map((char) => {
        const NumberComponent = Numbers[Number.parseInt(char)];
        return <NumberComponent height={height} width={width} />;
      })}
    </>
  );
}

function App() {
  const [counter, setCounter] = useState(0);
  const [now, setNow] = useState(new Date());
  const [confettiActive, setConfettiActive] = useState(false);
  const end = moment("2022-05-20 06:00");
  const diff = end.diff(now, "days") + "";

  let diffHours = (end.diff(now, "hour") % 24) + "";
  let diffMinutes = (end.diff(now, "minute") % 60) + "";
  let diffSeconds = (end.diff(now, "second") % 60) + "";

  diffHours = diffHours.length < 2 ? "0" + diffHours : diffHours;
  diffMinutes = diffMinutes.length < 2 ? "0" + diffMinutes : diffMinutes;
  diffSeconds = diffSeconds.length < 2 ? "0" + diffSeconds : diffSeconds;
  console.log(Math.abs(Number(diffSeconds)) % 5 === 0);

  useEffect(() => {
    const intervalTimer = setInterval(() => {
      if (!confettiActive && Math.abs(Number(diffSeconds)) % 5 === 0) {
        setConfettiActive(true);
      }
      setNow(new Date());
      if (confettiActive){
        setConfettiActive(false);
      }
    }, 1500 * (1 - (30 - end.diff(now, "days")) / 30));
    return () => {
      clearInterval(intervalTimer);
    };
  }); // "160px" width="100px" />;"316px" width="220px"

  if (end.diff(now) <= 0 || counter > 5) {
    return (
      <div className="App">
          <Confetti active={confettiActive} config={config} />
        <header className="App-header">
          <Timetable />
        </header>
      </div>
    );
  }

  return (
    <div className="App" onClick={() => setCounter(counter + 1)}>
      <header className="App-header">
        <div
          style={{
            height: 800,
            width: 800,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #aaa",
            borderRadius: "50%",
          }}
        >
          <div>
            <NumberFrame>{renderNumbers(diff, "240px", "140px")}</NumberFrame>
          </div>
          <Confetti active={confettiActive} config={config} />
          <TimeFrame>
            {renderNumbers(diffHours, "160px", "100px")}{" "}
            <div className="separator">:</div>
            {renderNumbers(diffMinutes, "160px", "100px")}
            <div className="separator">:</div>
            {renderNumbers(diffSeconds, "160px", "100px")}
          </TimeFrame>
        </div>
      </header>
    </div>
  );
}
const NumberFrame = styled.div`
  display: flex;
  flex-direction: row;
  width: 400px;
  align-items: center;
  justify-content: space-around;
`;

const TimeFrame = styled.div`
  margin-top: 40px;
  margin-bottom: 70px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 1200px;
  align-items: center;
  justify-content: space-around;
  transform: scale(0.6);

  @media (max-width: 1200px) {
    width: 380px;
    justify-content: default;
    flex-wrap: wrap;

    .separator {
      display: none;
    }
  }
`;

export default App;
