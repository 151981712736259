import React from "react";
import FaceContainer from "../base/FaceContainer";

const xPos1 = "0%";
const xPos2 = "20%";
const xPos3 = "40%";
const xPos4 = "60%";
const xPos5 = "80%";

const yPos1 = "0%";

/**
 * @param {Object} props
 * @param {boolean} props.enabled
 */
export default function TopLine({ enabled }) {
  return (
    <>
      <FaceContainer top={yPos1} left={xPos1} disabled={!enabled} />
      <FaceContainer top={yPos1} left={xPos2} disabled={!enabled} />
      <FaceContainer top={yPos1} left={xPos3} disabled={!enabled} />
      <FaceContainer top={yPos1} left={xPos4} disabled={!enabled} />
      <FaceContainer top={yPos1} left={xPos5} disabled={!enabled} />
    </>
  );
}
