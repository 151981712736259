import React from "react";
import FaceContainer from "../base/FaceContainer";

const xPos2 = "20%";
const xPos3 = "40%";
const xPos4 = "60%";
const yPos4 = "42%";


/**
 * @param {Object} props
 * @param {boolean} props.enabled
 */
export default function MiddleLine({ enabled }) {
  return (
    <>
      <FaceContainer disabled={!enabled} top={yPos4} left={xPos2} />
      <FaceContainer disabled={!enabled} top={yPos4} left={xPos3} />
      <FaceContainer disabled={!enabled} top={yPos4} left={xPos4} />
    </>
  );
}
