import React from "react";
import NumberFrame from "../base/NumberFrame";
import BottomLine from "./BottomLine";
import LeftLine from "./LeftLine";
import MiddleLine from "./MiddleLine";
import RightLine from "./RightLine";
import TopLine from "./TopLine";

export default function Seven({ height, width }) {
  return (
    <NumberFrame height={height} width={width}>
        <TopLine enabled />
        <MiddleLine />
        <BottomLine />
        <RightLine enabled />
        <LeftLine />
    </NumberFrame>
  );
}
