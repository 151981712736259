import React from "react";
import FaceContainer from "../base/FaceContainer";

const xPos5 = "80%";

const yPos1 = "0%";
const yPos2 = "14%";
const yPos3 = "28%";
const yPos4 = "42%";
const yPos5 = "56%";
const yPos6 = "70%";
const yPos7 = "84%";

/**
 * @param {Object} props
 * @param {boolean} props.enabled
 * @param {boolean} props.fhEnabled
 * @param {boolean} props.lhEnabled
 * @returns 
 */
export default function RightLine({ enabled, fhEnabled, lhEnabled }) {
  return (
    <>
      <FaceContainer top={yPos1} left={xPos5} disabled={!(enabled || fhEnabled)} />
      <FaceContainer top={yPos2} left={xPos5} disabled={!(enabled || fhEnabled)}/>
      <FaceContainer top={yPos3} left={xPos5} disabled={!(enabled || fhEnabled)}/>
      <FaceContainer top={yPos4} left={xPos5} disabled={!(enabled || fhEnabled || lhEnabled)}/>
      <FaceContainer top={yPos5} left={xPos5} disabled={!(enabled || lhEnabled)} />
      <FaceContainer top={yPos6} left={xPos5} disabled={!(enabled || lhEnabled)} />
      <FaceContainer top={yPos7} left={xPos5} disabled={!(enabled || lhEnabled)} />
    </>
  );
}
