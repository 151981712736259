import React from "react";
import Day from "./Day";
import styled from "styled-components";

export default function Timetable() {
  return (
      <div>
          <h3>🌞🥰 Spaniatur 🥰🌞</h3>
    <TimetableFrame>
      {programPerDay.map(day => <Day {...day} />)}
    </TimetableFrame>
    </div>
  );
}

const programPerDay = [
    { title: "Fredag", date: "20.05", itiniary: [
        { time: "04:00 - 10:10", description: "Busse til Gardermoen og fly til Malaga" },
        { time: "10:10 - 11:00", description: "Komme seg fra flyplassen til casa lèon" },
        { time: "11:10 - utover", description: "Gjemmekontor / feriiii 😎🌞🤑" },
    ] },
    { title: "Lørdag", date: "21.05", itiniary: [
        { time: "08:00 - 16:00", description: "Helg 🌞🌞" },
    ] },
    { title: "Søndag", date: "22.05", itiniary: [
        { time: "08:00 - 16:00", description: "Helg 🌞🌞" },
    ] },
    { title: "Mandag", date: "23.05", itiniary: [
        { time: "08:00 - 16:00", description: "Gjemmekontor 🌞🌞" },
    ] },
    { title: "Tirsdag", date: "24.05", itiniary: [
        { time: "08:00 - 16:00", description: "Gjemmekontor 🌞🌞" },
    ] },
    { title: "Onsdag", date: "25.05", itiniary: [
        { time: "09:00 - 11:00", description: "Buss til Granada 🌞🌞" },
        { time: "11:00 - 20:00", description: "Utforske Granada 🌞🌞" },
    ] },
    { title: "Torsdag", date: "26.05", itiniary: [
        { time: "08:00 - 16:00", description: "Granada 🌞🌞" },
        { time: "18:00 - 23:00", description: "Bursdagsmiddag 🌞🌞" },
    ] },
    { title: "Fredag", date: "27.05", itiniary: [
        { time: "17:00 - 19:30", description: "Buss hjem til Fuengirola 🌞🌞" },
    ] },
    { title: "Lørdag", date: "28.05", itiniary: [
        { time: "08:00 - 16:00", description: "Helg 🌞🌞" },
    ] },
    { title: "Søndag", date: "29.05", itiniary: [
        { time: "08:00 - 16:00", description: "Helg 🌞🌞" },
    ] },
    { title: "Mandag", date: "30.05", itiniary: [
        { time: "20:00 - 23:50", description: "Hjemreise 😭😭😭😭" },
    ] }
];

const TimetableFrame = styled.div`
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 20px;
`;
