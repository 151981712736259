import styled from "styled-components";

const NumberFrame = styled.div`
  position: relative;
  height: ${props => props.height};
  width: ${props => props.width};
  @media (max-width: 1200px) {
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

export default NumberFrame;