import React from "react";
import styled from "styled-components";
import ingi from "./ingihaha2.PNG";
import kami from "./kamihaha.PNG";
import joni from "./jonashaha.PNG";

const BILDER = [ingi, kami, joni];

export default function FaceContainer(props) {
    const imgSrc = BILDER[Math.floor(Math.random()*BILDER.length)];
    const rotation = Math.floor(Math.random()*360);
    const zIndex = Math.floor(Math.random()*30);
  return (
    <Frame
      disabled={props.disabled}
      style={{ top: props.top, left: props.left, zIndex }}
    >
      <img alt="hei" src={imgSrc} style={{ height: 40, width: 40, transform: `Rotate(${rotation}deg)`  }} />
    </Frame>
  );
}

const Frame = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: ${(props) => (props.disabled && false ? "none" : "#fff")};
  opacity: ${(props) => (props.disabled ? "0.05" : "1")};
  position: absolute;
  overflow: hidden;
`;
