import React from "react";
import styled from "styled-components";

export default function Day({ title, date, itiniary }) {
  return (
    <DayFrame>
      <h4 style={{ margin: 8, marginBottom: 0, padding: 8 }}>{title}</h4>
      <p style={{ marginTop: 0, padding: 4, marginBottom: 10, paddingBottom: 10, fontSize: 14, color: "#aaa" }}>{date}</p>
      <ItiniaryGrid>
        {itiniary.map((event) => (
          <>
            <p style={{ margin: 4, padding: 4, fontSize: 16, fontWeight: 500, textAlign: "left" }}>{event.time}</p>
            <p style={{ margin: 4, padding: 4, fontSize: 16, textAlign: "left" }}>{event.description}</p>
          </>
        ))}
      </ItiniaryGrid>
    </DayFrame>
  );
}

const ItiniaryGrid = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
`;

const DayFrame = styled.div`
  width: 240px;
  padding: 10px;
  text-align: center;

`;
